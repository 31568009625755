import React from "react";
import Script from "next/script";
import "styles/globals.css";

export default function App({ Component, pageProps }) {
  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
            lazyOnload
          />
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){ dataLayer.push(arguments); }
                  gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                  gtag('js', new Date());
                  gtag("consent", "default", {
                    ad_storage: "denied",
                    analytics_storage: "denied"
                  });
                `,
            }}
            lazyOnload
          />
        </>
      )}
      {process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID && (
        <>
          <Script
            id="axeptio-injector"
            dangerouslySetInnerHTML={{
              __html: `
                if (typeof window.axeptioSettings === "undefined") {
                  window.axeptioSettings = {
                    clientId: "${process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID}",
                    cookiesVersion: "${process.env.NEXT_PUBLIC_AXEPTIO_COOKIES_VERSION}",
                  };
                  
                  (function(d, s) {
                    let t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                    e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                    t.parentNode.insertBefore(e, t);
                  })(document, "script");
                }
              `,
            }}
          />

          <Script
            id="axeptio-process"
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  window._axcb = window._axcb || [];

                  window._axcb.push(function(axeptio) {
                    var consentSettings = {
                      ad_storage: "denied",
                      analytics_storage: "denied"
                    };

                    axeptio.on("cookies:complete", function(choices) {
                      if (choices.google_analytics) {
                        consentSettings.analytics_storage = "granted";
                        gtag("send", "pageview");
                      }

                      gtag("consent", "update", consentSettings);
                    })
                  })
              `,
            }}
          />
        </>
      )}
      <Component {...pageProps} />
    </>
  );
}
